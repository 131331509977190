import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const PageType = ({ type, resourceId }) => {
  if (process.env.NODE_ENV !== 'production') return <></>;

  // {"pageType":"home"}
  // {"pageType":"product","resourceType":"product","resourceId":4969120497796}
  // {"pageType":"collection","resourceType":"collection","resourceId":188270051460}

  if (!['home', 'product', 'collection'].includes(type)) throw Error(`Invalid page type: ${type}`);

  const pageData = {
    pageType: type,
  };

  if (['product', 'collection'].includes(type)) {
    if (!resourceId) throw Error(`No resource ID specified`);
    pageData.resourceType = type;
    pageData.resourceId = Number(resourceId);
  }

  return (
    <Helmet
      script={[{
        type: 'text/javascript',
        innerHTML: `
          window.ShopifyAnalytics.lib.page(
            null,
            ${JSON.stringify(pageData)}
          );
        `,
      }]}
    />
  );
}

PageType.propTypes = {
  type: PropTypes.oneOf(['home', 'collection', 'product']).isRequired,
  resourceId: PropTypes.number,
}

export default PageType;
